import React, {useContext, useMemo, useState} from "react"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import DataContext from "../../../context/DataContext"
import Typography from "../../reusable/Typography/Typography"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import PlusDash from "../../reusable/PlusDash"
import {getMenuTitles} from "../../../utility/Helper"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"

const categories = [
	{
		title: "Sofa",
		slugName: "sofa",
		link: "sofa",
		buttonTitle: "All Sofas",
		icon: "/assets/icons/sofa.svg"
	},
	{
		title: "Table",
		slugName: "table",
		link: "table",
		buttonTitle: "All Tables",
		icon: "/assets/icons/tables.svg"
	},
	{
		title: "Bed",
		slugName: "bed",
		link: "bed",
		buttonTitle: "All Beds",
		icon: "/assets/icons/bed.svg"
	},
	{
		title: "Aufbewahrung",
		slugName: "aufbewahrung",
		link: "aufbewahrung",
		buttonTitle: "Alle Aufbewahrung",
		icon: "/assets/icons/furniture.svg"
	},
	{
		title: "Add ons",
		slugName: "zubehor",
		link: "zubehor",
		buttonTitle: "All Add ons",
		icon: "/assets/icons/add.svg"
	}
]

const brandsArr = [
	{
		title: "Kave Home",
		slugName: "kave-home",
		link: "kave-home"
	},
	{
		title: "Bolia",
		slugName: "bolia",
		link: "bolia"
	},
	{
		title: "Innovation Living",
		slugName: "innovation-living",
		link: "innovation-living"
	},
	{
		title: "Furninova",
		slugName: "furninova",
		link: "furninova"
	},
	{
		title: "Conform",
		slugName: "conform_brand",
		link: "conform_brand"
	}
]
const footerPages = [
	{href: "/blog", text: "Blog"},
	{href: "/ueber-uns", text: "Über uns"},
	{href: "/terms-conditions", text: "mysofaplan.ch AGBs"},
	{href: "/bob-zero-agbs", text: "bob zero AGBs"},
	{href: "/kundendienst", text: "Kundendienst"},
	{href: "/datenschutz", text: "Datenschutz"}
]

const FooterContentMobile = () => {
	const {t} = useTranslation()
	const [togglePages, setTogglePages] = useState(false)
	const [toggleBrands, setToggleBrands] = useState(false)
	const [toggleCategories, setToggleCategories] = useState(false)

	const {collections, brands} = useContext(DataContext)
	const menuTitles = useMemo(() => getMenuTitles(collections, brands), [collections, brands])

	return (
		<section className="col-12 col-lg-7">
			<section className="row pb-5 px-2">
				<article className="col-12" style={{borderBottom: "1px solid #828486"}}>
					<div onClick={() => setTogglePages(!togglePages)} className={"footer-item   pointer"}
						style={{marginBottom: 18}}>
						<span style={{cursor: "pointer", marginTop: 14}}
							className="row g-2 g-md-3 d-flex justify-content-between align-items-top">
							<div className="col-11 overflow-hidden">
								<Typography variant="heading3"
									semanticTag="h3" style={{fontSize: 14}}>{t("Pages")}</Typography>
								<AccordionAnimation inProp={togglePages} duration={500} transTimingFn="ease-out">
									<ul className="list-unstyled  mt-3">
										{footerPages.map((link, index) => (
											<li key={index} className="footer-item">
												<Link href={link.href}>
													<a className="footer-link rounded">
														{t(link.text)}
													</a>
												</Link>
											</li>
										))}
									</ul>
								</AccordionAnimation>
							</div>
							<div className="col-1 d-flex justify-content-end" style={{width: 20, height: 20}}>
								<PlusDash iconColor="white" isOpen={togglePages}/>
							</div>
						</span>
					</div>
				</article>
				<article className="col-12" style={{borderBottom: "1px solid #828486"}}>
					<div onClick={() => setToggleBrands(!toggleBrands)} className={"footer-item  pointer"}
						style={{marginBottom: 18}}>
						<span style={{cursor: "pointer", marginTop: 14}}
							className="row g-2 g-md-3 d-flex justify-content-between align-items-top">
							<div className="col-11 overflow-hidden">
								<Typography variant="heading3" semanticTag="h3" style={{fontSize: 14}}>{t("Brands")}</Typography>
								<AccordionAnimation inProp={toggleBrands} duration={500} transTimingFn="ease-out">
									<ul className="list-unstyled mt-3">
										{brandsArr.map(item => <li key={item.title} className="footer-item d-block">
											<Link href={filterUrl({[urlParams.brand]: item.link}, null, null, t)}>
												<a className="footer-link rounded">
													{item.title}
												</a>
											</Link>
										</li>)}
									</ul>
								</AccordionAnimation>
							</div>
							<div className="col-1 d-flex justify-content-end" style={{width: 20, height: 20}}>
								<PlusDash iconColor="white" isOpen={toggleBrands}/>
							</div>
						</span>
					</div>
				</article>
				<article className="col-12">
					<div onClick={() => setToggleCategories(!toggleCategories)}
						className={"footer-item pointer"}
						style={{marginBottom: 18}}>
						<span style={{cursor: "pointer", marginTop: 14}}
							className="row g-2 g-md-3 d-flex justify-content-between align-items-top">
							<div className="col-11 overflow-hidden">
								<Typography variant="heading3" semanticTag="h3"
									style={{fontSize: 14}}>{t("Kategorien")}</Typography>
								<AccordionAnimation inProp={toggleCategories} duration={500} transTimingFn="ease-out">
									<ul className="list-unstyled mt-3">
										{categories.map(item => {
											return (<li key={item.title} className="footer-item w-100">
												<Link href={filterUrl({[urlParams.category]: item.link}, null, null, t)}>
													<a className="footer-link rounded" >
														{t(item.title)}
													</a>
												</Link>
											</li>)
										}
										)}
									</ul>
								</AccordionAnimation>
							</div>
							<div className="col-1 d-flex justify-content-end" style={{width: 20, height: 20}}>
								<PlusDash iconColor="white" isOpen={toggleCategories}/>
							</div>
						</span>
					</div>
				</article>
				<article className="col-12 mt-2">
					<ul className="list-unstyled mb-0">
						<li className="footer-item">
							<Typography variant={"bodyLgBlack"}
								style={{fontSize: 20, marginTop: 54}}>MyBikePlan AG</Typography>
						</li>
						<li className="footer-item" style={{marginTop: 26}}>
							<Link href={"tel:+41%2043%20505%2013%2018"}>{t("Sales: +41 43 505 13 18")}</Link>
						</li>
						<li className="footer-item" style={{marginTop: 20}}>
							<Link href={"mailto:hello@myhomeplan.ch"}>hello@myhomeplan.ch</Link>
						</li>
						<br/><br/>
						<li className="footer-item">
							<Typography variant={"bodyLgBlack"} className="mb-3" style={{fontSize: 20}}>Follow us on</Typography>
							<div className="row g-3">
								<div className="col-auto">
									<Link href={"https://www.instagram.com/myhomeplan.ch/"}>
										<a target="_blank" className="text-md text-white mt-5">
											<Image src={"/assets/icons/ig-white-icon.svg"} width={24} height={24}/>
										</a>
									</Link>
								</div>
								<div className="col-auto ms-2">
									<Link href={"https://www.facebook.com/profile.php?id=100095802481729"}>
										<a target="_blank" className="text-md text-white mt-5">
											<Image src={"/assets/icons/fb-white-icon.svg"} width={24} height={24}/>
										</a>
									</Link>
								</div>
							</div>
						</li>
					</ul>
				</article>
			</section>
		</section>
	)
}

export default FooterContentMobile
